<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      BMD Mapping
    </div>
    <datamapper :dataMaps="mapperData" @OnSave="HandleSave"/>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import button from '../uicomponents/button.vue';
export default {
 components: { button },
  name: 'BMDMapping',
  data () {
    return {
      standardRows: [],
      toBeMappedRows: [],
    }
  },
  created: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  mounted: function () {
  },
  computed: {
        ...mapGetters(['companyId']),
          mapperData(){
            var map = {};
            map['religioussociety'] = {};
            this.standardRows.forEach(r => {
              map['religioussociety'][r.id + ': ' + r.valueGER] = r;
            });
            map['religion'] = {};
            this.toBeMappedRows.forEach(r => {
              map['religion'][r.id + ': [' + r.religionId + '] : ' + r.name] = r;
            });/*
            for(let x = 0; x < 10; x++){
              map['religion'][x + ': ' + x] = x;
            }*/
            return map;
          },
          headerButtons() {
              let view = this;

              let state= {
                  buttons: []
              };
              state.buttons.push({
                  type: "block",
                  icon: "fa-refresh",
                  click: () => {
                     view.refresh();
                  },
                  tooltip: 'Daten neu laden'
              });
              state.buttons.push({
                  type: "block",
                  icon: "fa-plus",
                  click: ($event) => {
                     view.openNewFinancialAccountingGroup($event);
                  },
                  tooltip: 'Einen neuen FIBU Kreis anlegen'
              });
              return state;
          },
  },
  watch: {
    companyId: function(val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
  HandleSave(itemMap){
    console.log(itemMap);
  },
    refresh: function(force = false){
      let view = this;
      view.loading = true;
      view.getContent();
    },

    getContent: function(){
      let view = this;


      let url = '/api/enum/religioussociety';
      url = this.$helpers.appendParam("companyId", view.$store.state.companyId, url, '/api/enum/religioussociety');
      this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+view.$store.state.jwt },
        url: url,
      }).then(response => {
        view.standardRows = response.data;
      }).catch(function (error) {
        console.log(error);
      });
      url = '/api/sec/bmd/religion';
      url = this.$helpers.appendParam("companyId", view.$store.state.companyId, url, '/api/sec/bmd/religion');
      this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+view.$store.state.jwt },
        url: url,
      }).then(response => {
        view.toBeMappedRows = response.data.data;
      }).catch(function (error) {
        console.log(error);
      });


    }


  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>


.name-filter .fa-search {
    color: var(--contrast-4);
    position: absolute;
    top: 5px;
    left: 5px;
}

.half-width {
  width: 50%;
}

.fix-button-width {
  width: 150px;
}

.filter-input {
  margin-left: -45px;
  padding-left: 40px;
  width: calc(100% - 25px);
}

.list {
  width: calc(100% - 78px);
  margin: auto;
  height: 265px;
  box-shadow: inset 0 0 0 1px var(--contrast-3);
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
}
.list .scroll-content {
  padding: 5px;
  overflow-y: auto;
  height: 265px;
}

.list-entry {
  height: 35px;
  margin-bottom: 2px;
  border-radius: 100px;
  cursor: pointer;
}


.list-entry:hover {
  background: var(--sub-menu);
  cursor: pointer;
}
.list-entry.selected {
  background: #e51550;
  cursor: pointer;
}
.list-entry.selected > .label,
.list-entry:hover > .label{
  color: var(--bg);
  cursor: pointer;
}
.list-entry.selected > .label > .label-small,
.list-entry.hover > .label > .label-small {
  color: var(--bg) !important;
  cursor: pointer;
}


.list-entry > .label {
  float: left;
  font-size: 14px;
  font-family: DistrictProBook;
  color: var(--contrast-4);
  padding-top: 6px;
  cursor: pointer;
}
.list-entry > .label > .label-small {
  cursor: pointer;
  color:var(--contrast-5);
}


.picture {
  float: left;
  border-radius: 100px;
  background: var(--contrast-1);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.position-right{
  float: right;
  margin: 0;
  margin-right: -5px;
}
.pictureImg {
  border-radius: 100px;
  height: 25px;
  width: 25px;
}





/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--contrast-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--contrast-3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}
.has-header > .has-header > .scrollbar-container{
    height: calc(100% - var(--header-height) - var(--tab-menu-height)) !important;
    overflow-x: hidden;
    top: var(--header-height);
    padding: 0px 14px;
}
</style>
